import React, { Fragment, useMemo, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import throttle from 'lodash.throttle';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import Title from '../../molecules/title/title';
import { Wrapper } from '../../utils/components';
import withPreview from '../../utils/with-preview';
import { getCommonOrganismData } from '../../utils/functions';
import Accordion from '../accordion/Accordion';
import './FolderTreeAlphabetical.scss';
import { PrevArrow, NextArrow } from '../../molecules/arrows/arrows';
import { Link } from '../link';
import '../accordion/Accordion.scss';
import { accordionColors } from '../accordion/helpers';
import { Waypoint } from 'react-waypoint';
import { debounce } from 'lodash';
import ArrowRight from '../../atoms/static-icons/arrow-right';

const FolderTreeAlphabetical = ({ data, considerMenuSpace, isEmbedded }) => {
  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const accordions = useMemo(() => {
    const color = data?.accordionColor?.value[0];

    const folders = data?.folder?.folders || [];

    const accordions = folders.map((folder) => {
      const documents = folder?.documents || [];

      const accordionElements = documents.map((document) => {
        const file = {
          node: {
            contentUrl: document?.contentUrl,
            dateModified: document?.dateModified,
            encodingFormat: document?.encodingFormat,
            title: document?.title,
          },
        };

        return {
          content: {
            file,
            showDate: { value: data?.showDate?.value },
            showExtension: { value: true },
          },
        };
      });

      const accordionSection = [
        { content: { accordionElement: accordionElements } },
      ];

      return {
        value: folder.name,
        content: { accordionBodyColor: { value: [color] }, accordionSection },
        numberOfDocuments: folder.numberOfDocuments,
        numberOfDocumentFolders: folder.numberOfDocumentFolders,
        folderDescription: folder.description,
      };
    });

    return accordions;
  }, [data]);

  const letters = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  const [isClick, setisClick] = useState('A');

  const letterContainer = useRef(null);

  const [showArrowLeft, setshowArrowLeft] = useState(false);
  const [showArrowRight, setshowArrowRight] = useState(false);

  const scrollContainer = (direction) => {
    let amount = direction * (letterContainer.current.clientWidth / 2);
    letterContainer.current.scrollLeft += amount;
  };

  useEffect(() => {
    const windowListener = throttle(() => {
      // Show and hide right arrow with respect to the width of years and the width of the slider container
      let children = letterContainer.current.children;
      let totalWidth = 0;

      for (var i = 0; i < children.length; i++) {
        let childrenStyle = window.getComputedStyle(children[i]);
        let childrenMarginRight = parseInt(
          childrenStyle.getPropertyValue('margin-right')
        );
        let childrenMarginLeft = parseInt(
          childrenStyle.getPropertyValue('margin-left')
        );
        totalWidth +=
          children[i].clientWidth + childrenMarginLeft + childrenMarginRight;
      }

      if (totalWidth > letterContainer.current.clientWidth) {
        setshowArrowRight(true);
      } else {
        setshowArrowRight(false);
      }
    }, 250);

    const swipeListener = throttle(() => {
      // Hide right arrow because the scrollbar is at the end
      if (
        letterContainer.current.offsetWidth +
          letterContainer.current.scrollLeft ===
        letterContainer.current.scrollWidth
      ) {
        setshowArrowRight(false);
      }

      // Show right arrow because the scrollbar is moving in left direction
      if (
        letterContainer.current.offsetWidth +
          letterContainer.current.scrollLeft <
        letterContainer.current.scrollWidth
      ) {
        setshowArrowRight(true);
      }

      // Hide left arrow because the scrollbar is at the begin
      if (letterContainer.current.scrollLeft === 0) {
        setshowArrowLeft(false);
      }

      // Show left arrow because the scrollbar is moving in right direction
      if (letterContainer.current.scrollLeft > 0) {
        setshowArrowLeft(true);
      }
    }, 250);

    const currentSlider = letterContainer.current;
    currentSlider.addEventListener('scroll', swipeListener);
    window.addEventListener('resize', windowListener);
    windowListener();

    return () => {
      currentSlider.removeEventListener('scroll', swipeListener);
      window.removeEventListener('resize', windowListener);
    };
  }, []);

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-name='ex-partner'
      data-module-code='C-85'
      className={classNames(`folder-tree wrapper ${marginTop}`, {
        'folder-tree--colored-background': backgroundColor !== 'transparent',
      })}
      style={{
        backgroundColor: `var(--${backgroundColor})`,
      }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />
      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      <div
        className={classNames(
          'letters-list',
          isEmbedded && 'letters-list--embedded'
        )}
      >
        {data.showNavigationArrows?.value && showArrowLeft && (
          <PrevArrow
            className={classNames('arrow', {
              'show-arrow-left': showArrowLeft,
            })}
            onClick={() => scrollContainer(-1)}
          />
        )}
        <ul className='letters-list__items' ref={letterContainer}>
          {letters.map((letter) => {
            const enabledLetter = accordions.find((accordion) => {
              return accordion?.value.startsWith(letter);
            });
            return (
              <li
                key={letter}
                className={classNames('letters-list__item', {
                  'letters-list__item--active': isClick === letter,
                  'letters-list__item--disabled': !enabledLetter,
                })}
              >
                {enabledLetter ? (
                  <a
                    className={classNames('letters-list__link', {
                      'letters-list__link--active': isClick === letter,
                    })}
                    href={`#${letter}`}
                    onClick={(event) => {
                      event.preventDefault();
                      const href =
                        event.currentTarget.getAttribute('href') || '';
                      if (href.startsWith('#')) {
                        const id = href.substring(1); // takes every character after '#'
                        const target = document.getElementById(id);
                        if (target) {
                          target.scrollIntoView({ behavior: 'smooth' });
                        }
                      }
                      debounce(() => setisClick(letter), 650)();
                    }}
                  >
                    {letter}
                  </a>
                ) : (
                  <span>{letter}</span>
                )}
              </li>
            );
          })}
        </ul>
        {data.showNavigationArrows?.value && showArrowRight && (
          <NextArrow
            className={classNames('arrow', {
              'show-arrow-right': showArrowRight,
            })}
            onClick={() => scrollContainer(1)}
          />
        )}
      </div>
      {letters
        .filter((letter) =>
          accordions.some((accordion) => accordion?.value.startsWith(letter))
        )
        .map((letter) => (
          <Fragment key={letter}>
            <AnchorHooking id={letter} belowMenu={true}></AnchorHooking>
            <Waypoint
              key={letter}
              onEnter={() => setisClick(letter)}
              bottomOffset={'300px'}
              topOffset={'300px'}
            >
              <div className='letter-section'>
                <hr className='letter-section__line'></hr>
                <div className='letter-section__container'>
                  <span className='letter-section__letter'>{letter}</span>
                </div>
                <hr className='letter-section__line'></hr>
              </div>
            </Waypoint>
            {accordions
              .filter((accordion) => accordion?.value.startsWith(letter))
              .map((accordion, key) => (
                <Fragment key={key}>
                  {accordion?.numberOfDocuments === 0 &&
                  accordion?.numberOfDocumentFolders > 0 ? (
                    <div
                      className='accordion'
                      style={
                        accordionColors[
                          accordion?.accordionBodyColor?.value[0] ||
                            'verde-turchese'
                        ]
                      }
                    >
                      <Link
                        className='accordion__body'
                        to={accordion?.folderDescription.split('=')[1]}
                      >
                        <span className='accordion__title'>
                          {accordion?.value}
                        </span>
                        <ArrowRight className='accordion__icon'></ArrowRight>
                      </Link>
                    </div>
                  ) : (
                    <Accordion data={accordion} />
                  )}
                </Fragment>
              ))}
          </Fragment>
        ))}
    </Wrapper>
  );
};

export default withPreview(FolderTreeAlphabetical);
